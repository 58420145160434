.pageRoot[data-theme='lahip'], .modalRoot[data-theme='lahip'], .popoverRoot[data-theme='lahip'] {
  --brand-primary:                 #007b96;
}

.pageRoot[data-theme='allhome'], .modalRoot[data-theme='allhome'], .popoverRoot[data-theme='allhome'] {
  --brand-primary:                 #2A505A;
  --brand-primary-disabled:        #8e7d84;
  --brand-secondary:               #5C2E40;
  --brand-secondary-disabled:      #A58E97;

  --btn-default:                   var(--brand-primary);
  --btn-default-text:              #fff;
  --btn-default-hover:             #24464e;
  --btn-default-active:            #203d44;

  --btn-primary:                 var(--brand-secondary);
  --btn-primary-border:          var(--brand-secondary);
  --btn-primary-text:            #fff;
  --btn-primary-hover:           #53293a;
  --btn-primary-active:          #502837;

  --btn-secondary:                 #fff;
  --btn-secondary-border:          #fff;
  --btn-secondary-text:            var(--brand-primary);
  --btn-secondary-hover:           #d7e8ec;
  --btn-secondary-active:          #98c3cf;

  --brand-accent2:   #009ABC;
  --brand-accent3:     #CB8968;
  --brand-accent4:     #729F9D;
  --brand-accent5:     #2d852c;
}

.pageRoot[data-theme='familyresourcefinder'], .modalRoot[data-theme='familyresourcefinder'], .popoverRoot[data-theme='familyresourcefinder'] {
  --brand-primary: #3C4B93;
  --brand-primary-hover: #6570A5;
  --brand-primary-active: #252E55;
  --brand-primary-disabled: #9ca6c9;

  --btn-primary: #3C4B93;
  --btn-primary-hover: #6570A5;
  --btn-primary-active: #252E55;
  --btn-primary-disabled: #9ca6c9;

  --btn-default: #EFEFEF;
  --btn-default-hover: #DDDDDD;
  --btn-default-active: #CCCCCC;
  --btn-default-disabled: #F7F7F7;

  --brand-secondary: #6A69B4;

  --brand-tertiary: #84B4BA;

  --white-text-hover: #D8DBE8;
  --white-text-active: #B2B7D1;

  --white-btn-hover: #DDDDDD;
  --white-btn-active: #CCCCCC;
}

.pageRoot, .modalRoot, .popoverRoot {
  --brand-primary:                 #007b96;
  --brand-primary-light:           #4DB8D0;
  --brand-primary-lighter:         #99D7E4;
  --brand-primary-lightest:        #E6F5F8;
  --brand-primary-dark:            #007B96;
  --brand-primary-darker:          #005C71;
  --brand-primary-darkest:         #003E4B;
  --brand-primary-disabled:        #78B9C7;
  --brand-primary-disabled-light:  #F8FBFC;
  --brand-primary-disabled-bright: #8AC1D8;
  --brand-primary-hover:           #009ABC;
  --brand-primary-bright:          #0593C9;
  --brand-primary-brighter:        #049CD6;

  --btn-primary:                 var(--brand-primary);
  --btn-primary-border:          var(--brand-primary);
  --btn-primary-text:            #fff;
  --btn-primary-hover:           var(--brand-primary-hover);
  --btn-primary-active:          var(--brand-primary-darker);
  --btn-primary-disabled:        var(--brand-primary-disabled);
  --btn-primary-disabled-text:   var(--brand-primary-disabled);

  --btn-secondary:                 #fff;
  --btn-secondary-border:          #fff;
  --btn-secondary-text:            var(--brand-primary);
  --btn-secondary-hover:           var(--brand-primary-lightest);
  --btn-secondary-active:          var(--brand-primary-lighter);
  --btn-secondary-selected:        var(--brand-primary);
  --btn-secondary-unselected:      var(--brand-primary);
  --btn-secondary-disabled:        var(--brand-primary-disabled-light);
  --btn-secondary-disabled-text:   var(--brand-primary-disabled);

  --btn-default:                   #EFEFEF;
  --btn-default-text:              var(--brand-primary);
  --btn-default-hover:             #D9D9D9;
  --btn-default-active:            #999999;
  --btn-default-selected:          var(--brand-primary);
  --btn-default-unselected:        #4a4a4a;
  --btn-default-disabled:          #78B9C7;
}

$brand-tertiary:                 var(--brand-tertiary);

$brand-accent2:                  var(--brand-accent2);
$brand-accent3:                  var(--brand-accent3);
$brand-accent4:                  var(--brand-accent4);
$brand-accent5:                  var(--brand-accent5);

$btn-default:                    var(--btn-default);
$btn-default-text:               var(--btn-default-text);
$btn-default-hover:              var(--btn-default-hover);
$btn-default-active:             var(--btn-default-active);
$btn-default-selected:           var(--btn-default-selected);
$btn-default-unselected:         var(--btn-default-unselected);
$btn-default-disabled:           var(--btn-default-disabled);

$btn-primary:                    var(--btn-primary);
$btn-primary-text:               var(--btn-primary-text);
$btn-primary-border:             var(--btn-primary-border);
$btn-primary-hover:              var(--btn-primary-hover);
$btn-primary-active:             var(--btn-primary-active);
$btn-primary-disabled:           var(--btn-primary-disabled);

$btn-secondary:                  var(--btn-secondary);
$btn-secondary-text:             var(--btn-secondary-text);
$btn-secondary-border:           var(--btn-secondary-border);
$btn-secondary-hover:            var(--btn-secondary-hover);
$btn-secondary-active:           var(--btn-secondary-active);
$btn-secondary-selected:         var(--btn-secondary-selected);
$btn-secondary-unselected:       var(--btn-secondary-unselected);
$btn-secondary-disabled:         var(--btn-secondary-disabled);
$btn-secondary-disabled-text:    var(--btn-secondary-disabled-text);

$brand-primary:                  var(--brand-primary);
$brand-primary-light:            var(--brand-primary-light);
$brand-primary-lighter:          var(--brand-primary-lighter);
$brand-primary-lightest:         var(--brand-primary-lightest);
$brand-primary-dark:             var(--brand-primary-dark);
$brand-primary-darker:           var(--brand-primary-darker);
$brand-primary-darkest:          var(--brand-primary-darkest);
$brand-primary-disabled:         var(--brand-primary-disabled);
$brand-primary-disabled-light:   var(--brand-primary-disabled-light);
$brand-primary-disabled-bright:  var(--brand-primary-disabled-bright);
$brand-primary-hover:            var(--brand-primary-hover);
$brand-primary-active:           var(--brand-primary-active);
$brand-primary-bright:           var(--brand-primary-bright);
$brand-primary-brighter:         var(--brand-primary-brighter);
$white-text-hover:               var(--white-text-hover);
$white-text-active:              var(--white-text-active);
$white-btn-hover:                var(--white-btn-hover);
$white-btn-active:               var(--white-btn-active);

$brand-secondary:                var(--brand-secondary);
$brand-secondary-disabled:       var(--brand-secondary-disabled);

$black:                   #000000;
$near-black:              #00000033;
$white:                   #ffffff;
$grey-lightest:           #F5F5F5;
$grey-text:               #626262;
$grey-btn-hover:          #D9D9D9;
$white-blue-dark:         #BFDBE6;
$off-white:               #C9E1E7;
$black-text:              #171717;
$off-black:               #333333;
$carolina:                #006782;
$brand-carolina-darkest:  #033C50;
$brand-carolina:          #166781;
$brand-carolina-light:    #2F778F;
$brand-carolina-dark:     #0B5168;
$brand-carolina-lightest: #4C8CA2;
$brand-skyblue:           #1393CA;
$brand-skyblue-dark:      #057AAC;
$white-blue:              #E6F3F8;
$blue-disabled:           #C5DCE5;
$blue-bright:             #0093CC;
$brand-skyblue-light:     #38A3D1;
$brand-skyblue-lightest:  #60B6DA;
$grey-blue:               #EEF4F5;
$brand-skyblue-darkest:   #045A7F;
$red:                     #C73539;
$notification-red:        #f33b3b;
$white-red:               #FAF0F2;
$orange:                  #DD830B;
$orange-dark:             #de553d;
$purple-deep:             #6581D5;
$grey-blue-darkest:       #71868A;
$sea-green:               #2F4D5B;
$sea-green-dark:          #385763;
$dark-sea-green:          #2A4552;
$grey-dk-sea-green:       #7B8990;
$green:                   #42CB71;
$white-green:             #EEFFF4;
$goldenrod-light:         #FFF3C6;
$off-orellow-border:      #E2DEC5;
$goldenrod-darker:        #F0C209;
$yellow-callout-variant:  #F5CE3A;
$goldenrod:               #FFD83B;
$omellow:                 #FFA559;
$grey-background:         #F8F8F8;
$grey-white:              #E9E9E9;
$grey-hover-background:   #E7E7E7;
$grey-light-border:       #dcdcdc;
$grey-lightish:           #D7D7D7;
$grey-border:             #ced4da;
$grey-light:              #EFEFEF;
$grey:                    #cccccc;
$grey-medium:             #c8c8c8;
$grey-medium-darker:      #a8a8a8;
$grey-on-grey:            #A6A6A6;
$grey-lightest-text:      #666666; //lightest grey we should use for text
$grey-dark:               #999999;
$grey-darker:             #979797;
$grey-darkest:            #4a4a4a;
$grey-blue-dark:          #D5E0E2;
$grey-body-text:          #353535;
$grey-dark-on-light:      #7C7C7C;
$grey-darker-on-light:    #5F5F5F;
$peach:                   #f9eee9;
$shadow:                  rgba(0,0,0,.3);
$text-shadow:             rgba(0,0,0,.25);
$shadow-panel:            rgba(0, 0, 0, 0.05);
$shadow-panel-dark:       rgba(0, 0, 0, 0.12);
$clear:                   rgba(0, 0, 0, 0);
$red-lightest:				    #FF8B8B;

// Icon colors
$food:                    #8A9FD1;
$housing:                 #5DB8E6;
$employment:              #F3797F;
$family:                  #9A73B3;
$legal:                   #E172AC;
$money:                   #5AD4C0;
$education:               #69D269;
$health:                  #EA5D59;
$urgent:                  #F4A72D;
$all:                     $grey-darker;
$organization:            $carolina;
$facebook-blue:           #1877f2;
$linkedin-blue:           #0077b5;
$youtube-red:             #ff0000;
$twitter-blue:            #1da1f2;

// Highlight colors
$green-turquoise:         #69c5b8;
$red-light:               #FF6161;

// Logged-out homepage
$darkteal:                #0082B5;
$goldenrod-darkest:       #B69303;
$mauve:                   #BE4A93;
$google-red:	          #DC472D;

// Toast messages container colors
$toast-success:           #eefff4;
$toast-error:             #faf0f2;
$toast-info:                $white;

// Core component colors: Defined Abbreviations instead of using color names directly.
$boder-focused:           $brand-skyblue-lightest;
$error:                   $red;
$placeholder:             $grey-dark;
$page-background-color:   $brand-skyblue-dark;
$link:                    $brand-primary;
$link-visited:            $brand-primary;
$link-hover:              $brand-primary;
$link-focus:              $brand-skyblue-darkest;
