$header-height:               55px;
$mobile-header-height:        70px;
$page-header-height:          69px;
$footer-height:               76px;
$footer-height-extended:      525px;
$mobile-footer-height:        755px;
$sidebar-width:               250px;
$bottom-bar-height:           80px;
$collection-link-height:      56px;
$mobile-dropdown-link-height: 55px;
$four-collection-links:       200px;
$navbar-content-height:       38px;
$content-max-width:           1320px;
$modal-content-width:         350px;
$lahip-fixed-header-height:   120px;
$allhome-fixed-header-height: 65px;

.pageRoot[data-theme='allhome'] {
  padding-top: $allhome-fixed-header-height;
}

.pageRoot[data-theme='lahip'] {
  padding-top: 0px;
  @include for-lg-up {
    padding-top: $lahip-fixed-header-height;
  }
}

.pageRoot[data-theme='familyresourcefinder'] {
  padding-top: 0px;
}

.pageRoot {
  padding-top: $page-header-height;
}
