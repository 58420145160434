.pageRoot[data-theme='allhome'], .modalRoot[data-theme='allhome'] {
  --radius-rounded-btn: 5px;
  --radius-rounded-field: 5px;
}

.pageRoot[data-theme='familyresourcefinder'], .modalRoot[data-theme='familyresourcefinder'] {
  // placeholder
}

.pageRoot, .modalRoot{
  --radius-button: 6px;
  --radius-pill-btn: 60px;
  --radius-rounded-btn: 16px;
  --radius-rounded-field: 16px;
  --radius-pill-field: 60px;
  --radius-oval-container: 16px;
  --radius-circle: 50%;
  --radius-panel: 2px;
  --radius-card: 4px;
  --radius-toast-message: 25px;
}

$radius-button:                  var(--radius-button);
$radius-pill-btn:                var(--radius-pill-btn);
$radius-rounded-btn:             var(--radius-rounded-btn);
$radius-rounded-field:           var(--radius-rounded-field);
$radius-pill-field:              var(--radius-pill-field);
$radius-oval-container:          var(--radius-oval-container);
$radius-circle:                  var(--radius-circle);
$radius-panel:                   var(--radius-panel);
$radius-card:                    var(--radius-card);
$radius-toast-message:           var(--radius-toast-message);
