.pageRoot[data-theme='allhome'], .modalRoot[data-theme='allhome'] {
  --letter-spacing:             3px;
  --text-transform:             uppercase;
}

.pageRoot[data-theme='familyresourcefinder'], .modalRoot[data-theme='familyresourcefinder'] {
  // placeholder
}

.pageRoot, .modalRoot{
  --letter-spacing:             0px;
  --text-transform:             none;
}

$letter-spacing:                var(--letter-spacing);
$text-transform:                var(--text-transform);
