// IMPORTANT! This document is deprecated.
// Use ./breakpoints.scss instead.
// See viewport.context.js for more information.

$unused-xxs-grid: 321px; //smallest Apple devices
$unused-xs-grid: 426px;
$unused-sm-grid: 768px;
$unused-md-grid: 992px;
$unused-lg-grid: 1200px;

$unused-xxs-grid-custom: 381px;
$unused-xs-grid-custom: 411px;
$unused-sm-grid-custom: 700px;
$unused-md-grid-custom: 860px;
$unused-lg-grid-custom: 1080px;

$unused-search-md-size: 1154px;

$unused-lg-grid-plus:  1300px;

$unused-max-width:         600px;
$unused-max-width-wide:    1000px;
$unused-max-width-content: 730px;
$unused-max-width-centered: 850px;

@mixin unused-for-phone {
  @media only screen
  and (min-device-width: 320px)
  and (max-device-width: 568px) { @content; }
}
@mixin unused-for-large-phone {
  @media only screen
  and (min-device-width: 569px)
  and (max-device-width: 767px) { @content; }
}
@mixin unused-for-tablet-portrait {
  @media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (orientation: portrait)
  and (-webkit-min-device-pixel-ratio: 1){ @content; }
}
@mixin unused-for-tablet-landscape {
  @media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (orientation: landscape)
  and (-webkit-min-device-pixel-ratio: 1) { @content; }
}
@mixin unused-for-desktop {
  @media screen
  and (min-device-width: 1200px)
  and (max-device-width: 1920px)
  and (-webkit-min-device-pixel-ratio: 1) { @content; }
}

@mixin unused-for-mobile {
  @media screen
  and (max-width: 1024px) {
    @content;
  }
}

@mixin unusedMedia($keys...) {
  @each $key in $keys {
    @if ($key == phone) {
      @include unused-for-phone {
        @content
      }
    } @else if ($key == large-phone) {
      @include unused-for-large-phone {
        @content
      }
    } @else if ($key == tablet) {
      @include unused-for-tablet-portrait {
        @content
      }
      @include unused-for-tablet-landscape {
        @content
      }
    } @else if ($key == desktop) {
      @include unused-for-desktop {
        @content
      }
    }
  }
}

@mixin unusedLimitMaxWidth {
  width: 100% !important;
  max-width: 1240px !important;
  margin-left: auto !important;
  margin-right: auto !important;

  @include unusedMedia(desktop) {
      max-width: 1240px !important;
  }

  @include unusedMedia(phone) {
      max-width: 100vw;
  }

  @include unusedMedia(tablet) {
      max-width: 100vw;
  }
}

@media only screen and (max-width: ($unused-lg-grid-custom - 1)) {
  .hide-below-lg-custom {
    display: none !important;
  }
}

@media only screen and (max-width: ($unused-lg-grid-plus - 1)) {
  .hide-below-lg-plus {
    display: none !important;
  }
}

@media only screen and (min-width: $unused-lg-grid-custom) {
  .hide-above-lg-custom {
    display: none !important;
  }
}

@media only screen and (min-width: $unused-sm-grid-custom) {
  .visible-sm-custom {
    display: none !important;
  }
}

@media only screen and (max-width: $unused-sm-grid-custom - 1) {
  .hide-xs-custom {
    display: none !important;
  }
}