// HOW TO USE BREAKPOINTS
// (See viewport.context.js for more breakpoint information.)

// Scss should be written xs first with larger sizes added in increasing order as needed
// Use inclusive sizing where possible ([size]-up) to decrease chances of missed styles

// Most of our files should be structured like this:
// .wrapper {
//     background-color: red;
//     margin-bottom: 20px;
//     padding: 40px;
//     @include for-md-up {
//         background-color: green;
//              // margin-bottom remains 20px
//         padding: 20px;
//     }
//     @include for-lg-up {
//         background-color: blue;
//              // margin-bottom remains 20px
//              // padding remains 20px
//     }
// }

$xsMin: 320px;
$xsMax: 575px;
$smMin: 576px;
$smMax: 767px;
$mdMin: 768px;
$mdMax: 991px;
$lgMin: 992px;
$lgMax: 1199px;
$xlMin: 1200px;
$xlMax: 1399px;
$xxlMin: 1400px;

@mixin for-xs-only {
  @media (min-width: $xsMin) and (max-width: $xsMax) {
    @content;
  }
}
@mixin for-sm-up {
  @media (min-width: $smMin) {
    @content;
  }
}
@mixin for-sm-only {
  @media (min-width: $smMin) and (max-width: $smMax) {
    @content;
  }
}
@mixin for-md-up {
  @media (min-width: $mdMin) {
    @content;
  }
}
@mixin for-md-only {
  @media (min-width: $mdMin) and (max-width: $mdMax) {
    @content;
  }
}
@mixin for-lg-up {
  @media (min-width: $lgMin) {
    @content;
  }
}
@mixin for-lg-only {
  @media (min-width: $lgMin) and (max-width: $lgMax) {
    @content;
  }
}
@mixin for-xl-up {
  @media (min-width: $xlMin) {
    @content;
  }
}
@mixin for-xl-only {
  @media (min-width: $xlMin) and (max-width: $xlMax) {
    @content;
  }
}
@mixin for-xxl-up {
  @media (min-width: $xxlMin) {
    @content;
  }
}
@mixin setMaxWidth {
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @include for-lg-up {
    max-width: 1240px;
  }
}