@import '@/assets/stylesheets/common/colors';
@import '@/assets/stylesheets/common/fonts';
@import '~normalize.css';
@import '@/assets/stylesheets/common/screens';

html,
body,
body > div:first-child,
div#__next,
div#__next > div {
height: 100%;
}

body {
  font-family: $source_sans_pro;
  font-size: $font-size-normal;
}