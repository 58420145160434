@import '@/features/familyresourcefinder/styles/typography';

$lucida: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
$source_sans_pro: "Source Sans Pro", Arial, sans-serif;

.pageRoot {
  font-family: $source_sans_pro;
}

.pageRoot[data-theme='familyresourcefinder'], .modalRoot[data-theme='familyresourcefinder'] {
  font-family: $lato;
}

//allow smaller fonts in PDFs since they are meant to be printed
$font-size-pdf-tiny: 10px;
$font-size-pdf-smaller: 11px;
$font-size-pdf-small: 12px;
$font-size-pdf-smallish: 13px;
$font-size-pdf-mid: 14px;
$font-size-pdf-normal: 15px; 

//short-term smaller fonts for layout-breaking issues
$exception-font-size-smaller: 11px;
$exception-font-size-smallish: 13px;
$exception-font-size-mid: 14px;
$font-size-form: 22px;

// Legacy small font sizes, try to avoid using outside
// of the Admin panel or other internal features
$font-size-tiny: 10px;
$font-size-smaller: 11px;
$font-size-small: 12px;
$font-size-smallish: 13px;
$font-size-mid: 14px;

// Heading font sizes
$font-h1: 36px;
$font-h2: 24px;
$font-h3: 18px;
$font-h4: 16px;
$font-body: 16px;
$font-body-small: 14px;
$font-meta: 12px;

// Accessible font sizes:
// Use 16px as the minimum screen font size
$font-size-normal: 16px;
$font-size-midlarger: 17px;
$font-size-large: 18px;
$font-size-xslarge: 19px;
$font-size-larger: 20px;
$font-size-xlarge: 22px;
$font-size-xlarger: 24px;
$font-size-xxlarge: 26px;
$font-size-xxlarger: 30px;
$font-size-xxxlarge: 34px;
$font-size-xxxxlarge: 40px;
$font-size-headline: 42px;
$font-size-hero-size: 54px;
$font-weight-thin: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 600;
$font-weight-bold: 700;

.font-weight-bold {
  font-weight: $font-weight-medium;
}
.font-weight-bolder {
  font-weight: $font-weight-bold;
}