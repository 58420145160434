$spacing-1:     5px;
$spacing-2:     $spacing-1 * 2;
$spacing-3:     $spacing-1 * 3;
$spacing-4:     $spacing-1 * 4;
$spacing-5:     $spacing-1 * 5;
$spacing-6:     $spacing-1 * 6;
$spacing-7:     $spacing-1 * 7;
$spacing-8:     $spacing-1 * 8;
$spacing-9:     $spacing-1 * 9;
$spacing-10:    $spacing-1 * 10;
$spacing-11:    $spacing-1 * 11;
$spacing-12:    $spacing-1 * 12;
$spacing-13:    $spacing-1 * 13;
$spacing-14:    $spacing-1 * 14;
$spacing-15:    $spacing-1 * 15;
$spacing-16:    $spacing-1 * 16;
$spacing-17:    $spacing-1 * 17;
$spacing-18:    $spacing-1 * 18;
$spacing-19:    $spacing-1 * 19;
$spacing-20:    $spacing-1 * 20;
$spacing-21:    $spacing-1 * 21;
$spacing-22:    $spacing-1 * 22;
$spacing-23:    $spacing-1 * 23;
$spacing-24:    $spacing-1 * 24;
$spacing-25:    $spacing-1 * 25;
$spacing-26:    $spacing-1 * 26;
$spacing-49:    $spacing-1 * 49;

$size-ratio-1: 5%;
$size-ratio-2: $size-ratio-1 * 2;
$size-ratio-3: $size-ratio-1 * 3;
$size-ratio-4: $size-ratio-1 * 4;
$size-ratio-5: $size-ratio-1 * 5;
$size-ratio-6: $size-ratio-1 * 6;
$size-ratio-7: $size-ratio-1 * 7;
$size-ratio-8: $size-ratio-1 * 8;
$size-ratio-9: $size-ratio-1 * 9;
$size-ratio-10: $size-ratio-1 * 10;

.bottom-spacer {
  padding-bottom: $spacing-4;
}
